import { useEffect } from 'react';
import { FaLink } from 'react-icons/fa6';
import { ButtonColor, ButtonVariant } from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import { Line } from 'src/components/atoms/Line';
import { Loader } from 'src/components/atoms/Loader';
import { Row } from 'src/components/atoms/Row';
import {
  isAdditionalDocumentProofType,
  isIncomeDocumentProofType
} from 'src/components/DUP/molecules/WizardSubmit';
import useLanguage from 'src/context/Language/useLanguage';
import getDocumentTypes from 'src/features/DUP/helpers/getDocumentTypes';
import getProofErrorMessage, {
  PROOF_ERROR_KEYS
} from 'src/features/DUP/helpers/getProofErrorMessage';
import { FeatureFlagKeys, useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { DupScreenStepProps } from 'src/pages/DUPPage/DUPWizard';
import { Account } from 'src/types/applicantExperienceApi';
import { Proof, SessionApplication } from 'src/types/api';
import {
  DocumentRow,
  EditButton,
  NoDocumentsContainer,
  PersonalInfoContainer,
  ReviewContainer,
  ReviewSectionContent,
  SectionContainer,
  SectionHeader,
  SectionTitle,
  StyledText
} from './styles';

const ApplicationInfo = ({
  application,
  setActiveStep,
  proofs,
  hasPayrollAccounts,
  accountsConnected
}: Pick<DupScreenStepProps, 'application' | 'setActiveStep'> & {
  proofs: Proof[];
  hasPayrollAccounts: boolean;
  accountsConnected: Account[] | null;
}) => {
  const { translate: t } = useLanguage();

  const incomeDocuments = proofs.filter(isIncomeDocumentProofType);
  const additionalDocuments = proofs.filter(isAdditionalDocumentProofType);

  const {
    loading: featureFlagValueLoading,
    onGetFeatureFlagValue,
    data: isConnectedPayrollFFEnabled
  } = useFeatureFlag({
    projectName: 'fraud-platform',
    featureFlagKey: FeatureFlagKeys.connectedPayroll
  });

  useEffect(() => {
    onGetFeatureFlagValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (featureFlagValueLoading) {
    return <Loader isFixed />;
  }

  return (
    <ReviewContainer>
      <ReviewInfoSection
        title={t('dup_wizard_step_1_title')}
        step={1}
        setActiveStep={setActiveStep}
      >
        <PersonalInfo application={application} />
      </ReviewInfoSection>

      <ReviewInfoSection
        title={t('dup_wizard_proof_of_income')}
        step={2}
        setActiveStep={setActiveStep}
      >
        {isConnectedPayrollFFEnabled && accountsConnected && accountsConnected.length > 0 && (
          <ConnectedPayrollAccounts accountsConnected={accountsConnected} />
        )}

        <DocumentList
          proofs={incomeDocuments}
          hasPayrollAccounts={hasPayrollAccounts}
          isIncomeSection
        />
      </ReviewInfoSection>

      <ReviewInfoSection
        title={t('dup_wizard_step_3_title')}
        step={3}
        setActiveStep={setActiveStep}
      >
        <DocumentList
          proofs={additionalDocuments}
          hasPayrollAccounts={false} // Not needed for supporting documents section
          isIncomeSection={false}
        />
      </ReviewInfoSection>
    </ReviewContainer>
  );
};

const ReviewInfoSection = ({
  title,
  setActiveStep,
  step,
  children
}: {
  title: string;
  setActiveStep: (step: number) => void;
  step: number;
  children: React.ReactNode;
}) => {
  return (
    <>
      <Line />
      <SectionContainer>
        <SectionHeader>
          <SectionTitle isBold>{title}</SectionTitle>
          <EditButton
            name="edit"
            variant={ButtonVariant.outline}
            color={ButtonColor.tertiary}
            onClick={() => setActiveStep(step - 1)}
          >
            Edit
          </EditButton>
        </SectionHeader>
        <ReviewSectionContent>{children}</ReviewSectionContent>
      </SectionContainer>
    </>
  );
};

const PersonalInfo = ({ application }: { application: SessionApplication }) => {
  const { unit, firstName, middleInitial, lastName, phone, email } = application;
  const { translate: t } = useLanguage();

  return (
    <PersonalInfoContainer>
      <StyledText>
        {firstName} {middleInitial} {lastName}
      </StyledText>
      {!!unit && (
        <StyledText>
          {t('dup_wizard_unit')} {unit}
        </StyledText>
      )}
      {!!phone && <StyledText>{phone}</StyledText>}
      <StyledText>{email}</StyledText>
    </PersonalInfoContainer>
  );
};

const DocumentList = ({
  proofs,
  hasPayrollAccounts,
  isIncomeSection
}: {
  proofs: Proof[];
  hasPayrollAccounts: boolean;
  isIncomeSection: boolean;
}) => {
  const { translate: t } = useLanguage();

  // Logic for displaying appropriate message based on section and whether accounts/documents exist
  const showNoIncomeSourcesMessage = isIncomeSection && proofs.length === 0 && !hasPayrollAccounts;
  const showNoDocumentsMessage = !isIncomeSection && proofs.length === 0;

  return (
    <>
      {showNoIncomeSourcesMessage && (
        <NoDocumentsContainer>{t('dup_wizard_no_income_sources_uploaded')}</NoDocumentsContainer>
      )}
      {showNoDocumentsMessage && (
        <NoDocumentsContainer>{t('dup_wizard_no_documents_uploaded')}</NoDocumentsContainer>
      )}
      {proofs.length > 0 && (
        <div style={{ marginTop: hasPayrollAccounts ? '16px' : '0' }}>
          {proofs.map((proof, i) => {
            const label = getDocumentTypes(t).find((type) => type.value === proof.type)?.label;
            const error = proof.jobs_error?.length ? proof.jobs_error[0] : '';

            return (
              <DocumentRow key={proof.id} error={Boolean(error)}>
                <Icon icon="file_grey" />
                <Row wrap="wrap">
                  <span>{proof.fileName || `${label} ${proofs.length > 1 && `#${i + 1}`}`}</span>
                  {Boolean(error) && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: getProofErrorMessage(error as keyof typeof PROOF_ERROR_KEYS, t)
                      }}
                    />
                  )}
                </Row>
              </DocumentRow>
            );
          })}
        </div>
      )}
    </>
  );
};

const ConnectedPayrollAccounts = ({ accountsConnected }: { accountsConnected: Account[] }) => {
  const { translate: t } = useLanguage();

  return accountsConnected.length > 0 ? (
    <Row>
      <FaLink />
      <StyledText>&nbsp;&nbsp;{t('dup_wizard_connected_accounts')}:&nbsp;</StyledText>
      <StyledText>{accountsConnected.map((account) => account.name).join(', ')}</StyledText>
    </Row>
  ) : null;
};

export default ApplicationInfo;
